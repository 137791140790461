import './theme/box.css'
import qmModule from './store/index'
import axios from 'axios'
import VueRouter from 'vue-router'
import VueAxios from 'vue-axios'
import { setSs, getSs, setLs, getLs, clearLs, clearSs, removeSs, removeLs } from './lib/storage'

const liangdian = {
    install(Vue, options={
        filter: [],
        request: {
            success:()=>{},
            error:()=> {}
        },
        response: {
            success:()=>{},
            error:()=> {}
        }
    }) {
        //添加拦截器
        axios.interceptors.request.use(function (config) {
            options.request.success(config, options)
            return config
        }, function (error) {
            options.request.error(error)
            return Promise.reject(error)
        })
        axios.interceptors.response.use(function (response) {
            if(response.data.code && response.data.code!==200) {
                let msg = response.data.msg
                options.response.error(msg, response.data.code)
                return Promise.reject(msg)
            }
            options.response.success()
            if(response.data.result) {
                return response.data.result
            }
            return response.data
        }, function (error) {
            console.error(error)
            options.response.error()
            return Promise.reject(error)
        })
        Vue.use(VueAxios, axios)
        //
        Vue.prototype.$setSs = setSs
        Vue.prototype.$getSs = getSs
        Vue.prototype.$setLs = setLs
        Vue.prototype.$getLs = getLs
        Vue.prototype.$clearLs = clearLs
        Vue.prototype.$clearSs = clearSs
        Vue.prototype.$removeSs = removeSs
        Vue.prototype.$removeLs = removeLs
        //
        setStore(options.store)
        setRouter()
    }
}

function setStore(store) {
    if(store) {
        store.registerModule('qmModule',qmModule)
    }
}
function setRouter() {
    //
    const originalPush = VueRouter.prototype.push
    const originalReplace = VueRouter.prototype.replace
    // push
    VueRouter.prototype.push = function push (location, onResolve, onReject) {
        if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
        return originalPush.call(this, location).catch(err => err)
    }
    // replace
    VueRouter.prototype.replace = function push (location, onResolve, onReject) {
        if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
        return originalReplace.call(this, location).catch(err => err)
    }
}
export default liangdian