import Vue from 'vue'
import { setSs, getSs, setLs, getLs, clearLs, clearSs, removeSs, removeLs } from './../lib/storage'
import { toTree } from "./../lib/toTreeList";
import staicrouter from "./../router/index"

export default {
  namespaced: true,
  state: {
    menuData: [],
    userinfo: {}
  },
  getters: {

  },
  //通过mutations来修改state中的值
  mutations: {
    menuData(state, value) {
      state.menuData = value
    },
    userinfo(state, value) {
      state.userinfo = value
    }
  },
  actions: {
    setMenu({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('/gateway/menubyuser').then(res=> {
          let rows = res.rows.filter(item=>(item.menutype===0 && item.menushow===1))
          let menlist = rows.sort(function(a, b){return a.menulevel - b.menulevel})
          let menuData = toTree(menlist)
          let routerData = {
            path: '/',
            name: 'admin',
            component: () => import('@/views/admin.vue'),
            children: []
          }
          let children = []
          if(menuData[0]) {
            if(!getSs('menuactivename')) {
              setSs('menuactivename',menuData[0].menupath)
            }
            routerData.redirect = menuData[0].menupath
          }
          one(menuData)
          function one(arr) {
            for(let i = 0; i < arr.length; i++) {
              let item = arr[i]
              let obj = {
                path: item.menupath,
                name: item.menucode?item.menucode:item.menupath.replace('/','_'),
                component: () => import(`@/views${item.menupath}.vue`)
              }
              if(item.children && item.children.length>0) {
                obj.redirect = item.children[0].menupath
                one(item.children)
              }
              children.push(obj)
            }
          }
          children = [...children, ...staicrouter]
          routerData.children = children
          payload.router.addRoute(routerData)
          payload.router.addRoute({
            path:'*',
            redirect:'/404'
          })
          commit('menuData', menuData)
          resolve()
        }).catch(e=> {
          reject(e)
        })
      })
    },
    logout({ commit, state }, payload) {
      sessionStorage.clear()
      localStorage.clear()
      if(payload.router) {
        payload.router.push({name:'login'})
        location.reload()
      }
    }
  }
}
