import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/main'
  },
  {
    path: '/main',
    name: 'main',
    component: () => import('../views/main.vue')
  },
  {
    path: '/boxlibrary',
    name: 'boxlibrary',
    component: () => import('../views/boxlibrary.vue')
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('../views/help.vue')
  },
  {
    path: '/favorites',
    name: 'favorites',
    component: () => import('../views/favorites.vue')
  },
  {
    path: '/modelitems/:id',
    name: 'modelitems',
    component: () => import('../views/modelitems.vue')
  },
  {
    path: '/placeorder',
    name: 'placeorder',
    component: () => import('../views/placeorder.vue')
  },
  {
    path: '/placeorderitems',
    name: 'placeorderitems',
    component: () => import('../views/placeorderitems.vue')
  },
  {
    path: '/ordermanage',
    name: 'ordermanage',
    component: () => import('../views/ordermanage.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/admin.vue')
  },
  {
    path: '*',
    name: 'notfound',
    component: () => import('../views/notfound')
  },
  {
    path: '/templatecenter',
    name: 'templatecenter',
    component: () => import('../views/templatecenter')
  },
  {
    path: '/templatefruit',
    name: 'templatefruit',
    component: () => import('../views/templatefruit')
  },
  // {
  //   path: '/templatevegetable',
  //   name: 'templatevegetable',
  //   component: () => import('../views/templatevegetable')
  // },
  {
    path: '/getstartedquickly',
    name: 'getstartedquickly',
    component: () => import('../views/getstartedquickly')
  },
  {
    path: '/functionintroduction',
    name: 'functionintroduction',
    component: () => import('../views/functionintroduction')
  },
  {
    path: '/accountmanagement',
    name: 'accountmanagement',
    component: () => import('../views/accountmanagement')
  },
  {
    path: '/orderspayments',
    name: 'orderspayments',
    component: () => import('../views/orderspayments')
  },
  {
    path: '/agreements',
    name: 'agreements',
    component: () => import('../views/agreements')
  },
  {
    path: '/packagingknowledge',
    name: 'packagingknowledge',
    component: () => import('../views/packagingknowledge')
  },
  {
    path: '/filerelated',
    name: 'filerelated',
    component: () => import('../views/filerelated')
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: () => import('../views/faqs')
  },
  {
    path: '/issue/:id',
    name: 'issue',
    component: () => import('../views/issue.vue')
  },
  {
    path: '/unlogin',
    name: 'unlogin',
    component: () => import('../views/indexunlogin')
  },
]

const router = new VueRouter({
  routes
})

export default router
