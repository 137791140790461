var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('button',{staticClass:"button-style",style:({
      width: _vm.width + 'px',
      height: _vm.height + 'px',
      borderRadius: _vm.radius + 'px',
      color: _vm.color,
      background: _vm.bgcolor,
    })},[_vm._v(" "+_vm._s(_vm.text)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }