<template>
  <div>
    <input
      :class="status ? 'input-style': 'input-style-false' "
      :style="{ width: width + 'px', height: height + 'px' }"
      :placeholder="placeholder"
      :disabled="disabled"
      v-model="inputText"
      @blur="relusHandle"
    />
    <div :class="status ? 'input-true' : 'input-false'">
      {{ rules[question].message }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inputText: "",
      status: true,
      question: 0,
    };
  },
  props: {
    // 提示文字
    placeholder: {
      type: String,
      default: "",
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 输入的内容
    value: {
      type: String,
      default: "",
    },
    // 正则检验
    rules: {
      type: Array,
      default: [],
    },
    // 设置高度和宽度
    width: {
      type: String,
      default: 240,
    },
    height: {
      type: String,
      default: 35,
    },
  },
  methods: {
    // 失焦事件（用于正则检验）
    relusHandle(e) {
      this.status = true;
      // 遍历整个正则然后依次判断
      for (let i = 0; i < this.rules.length; i++) {
        // 判断是否符合规则
        if (this.rules[i].required === true) {
          if (e.target.value === "") {
            this.status = false;
            this.question = i;
          }
          continue;
        }
        let str = eval(this.rules[i].type);
        if (!str.test(e.target.value)) {
          this.status = false;
          this.question = i;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.input-style {
  border: 1px solid #d8dee5;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #323232;
  padding: 10px 15px;
}
.input-style-false {
  border: 1px solid red;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #323232;
  padding: 10px 15px;
}
input {
  caret-color: #ff6000;
}
input:focus {
  outline: 0;
  border: 1px solid #ff6000;
}
.input-false {
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #e34d59;
  line-height: 17px;
}
.input-true {
  display: none;
}
</style>
