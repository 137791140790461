import Vue from "vue";
//自定义组件
import Layout from "@/components/Layout";
// 引入组件库中的组件
import {
  Button,
  Form,
  FormItem,
  Icon,
  Input,
  RadioGroup,
  Radio,
  Menu,
  MenuItem,
  Submenu,
  Sider,
  MenuGroup,
  Select,
  Option,
  Carousel,
  CarouselItem,
  Divider,
  Card,
  Row,
  Col,
  Modal,
  Breadcrumb,
  BreadcrumbItem,
  Avatar,
  Poptip,
  Timeline,
  TimelineItem,
  Cascader,
  Tooltip,
  Affix,
  Message,
  Page,
  Tabs,
  TabPane,
  Checkbox,
  Upload,
  Dropdown,
  DropdownMenu,
  DropdownItem
} from "iview";
const iviewLsit = {
  Button,
  Form,
  FormItem,
  Icon,
  Input,
  RadioGroup,
  Radio,
  Menu,
  MenuItem,
  Submenu,
  Sider,
  MenuGroup,
  Select,
  Option,
  Carousel,
  CarouselItem,
  Divider,
  Card,
  Row,
  Col,
  Modal,
  Breadcrumb,
  BreadcrumbItem,
  Avatar,
  Poptip,
  Timeline,
  TimelineItem,
  Cascader,
  Tooltip,
  Affix,
  Message,
  Page,
  Tabs,
  TabPane,
  Checkbox,
  Upload,
  Dropdown,
  DropdownMenu,
  DropdownItem
};
for (let key in iviewLsit) {
  Vue.component(key, iviewLsit[key]);
}
Vue.prototype.$Message = Message
Vue.component("Layout", Layout);
