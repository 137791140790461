<template>
  <div>
    <Dropdown trigger="custom" :visible="dropDownShow">
      <div class="search-border" id="makewidth" tabindex="111">
        <div
            :class="!showlist ? 'pullist-hidden' : 'pullist'"
            @mouseleave="showlist = false"
        >
          <div
              class="pullist-item"
              style="margin-top: 20px"
              @click="changelist('全部')"
          >
            全部
          </div>
          <div class="pullist-item" @click="changelist('模型')">模型</div>
          <div class="pullist-item" @click="changelist('盒型')">盒型</div>
        </div>
        <div class="all-button-Box" @click="showlist = !showlist">
          <div class="all-button">{{ showitem }}</div>
          <div class="button-tips" :class="!showlist ? 'icon-show' : 'icon-unshow'">
            <img class="button-ico" src="../../assets/searchCmp/down.svg" />
          </div>
        </div>
        <div class="search-input-box">
          <input
              placeholder="精品模板"
              v-model="inputContent"
              class="search-input"
          />
        </div>
        <div class="search-btn searchbtn" @click="SearchHandle">
          <img
              class="search-btn-bg1 searchbtn"
              src="../../assets/searchCmp/searchNorml.png"
          />
          <img
              class="search-btn-bg2 searchbtn"
              src="../../assets/searchCmp/searchHover.png"
          />
        </div>
      </div>
      <Dropdown-menu  slot="list">
        <div style="width: 700px; max-height: 600px;overflow: auto;">
          <div style="display: flex;padding: 15px" v-for="(item,index) in searchList" @click="goHandle(item)">
            <div style="width: 150px">
              <img style="width: 100%;border-radius: 10px" :src="item.thumb"/>
            </div>
            <div style="padding: 15px;width: 280px;overflow: hidden">
              <div style="font-size: 18px">{{item.title}}</div>
              <div style="margin-top: 18px;color: #5b6269">{{item.type==2?'模板':'盒型'}}</div>
            </div>
          </div>
          <div style="width: 100%" v-if="searchList.length===0">
            <img style="width: 50%;margin-left: 25%" src="../../assets/nodata.png"/>
          </div>
        </div>
      </Dropdown-menu>
    </Dropdown>
  </div>

</template>

<script>
export default {
  data() {
    return {
      showlist: false,
      showitem: "全部",
      inputContent: "",
      searchList:[],
      dropDownShow:false,
      boxType:0,
    };
  },
  mounted() {
    document.addEventListener('click', (e) => {
      if (e.target.className!=='search-btn-bg2 searchbtn') {
        this.dropDownShow = false
      }
    })
  },
  methods: {
    changelist(item) {
      this.showitem = item;
      this.showlist = false;
      if(item==="全部"){
        this.boxType=0
      }else if(item==="模型"){
        this.boxType=2
      }else {
        this.boxType=1
      }
    },
    SearchHandle(){
      if(this.inputContent===''){
        this.$Message.info('请输入内容');
        return
      }
      this.dropDownShow = true
      this.axios.get(IP+`/prod-api/box/search?title=${this.inputContent}&type=${this.boxType}`).then((res)=>{
        console.log(IP+`/prod-api/box/search?title=${this.inputContent}&type=${this.boxType}`)
        if(res.code===200){
          this.searchList=res.data;
        }
      })
    },
    goHandle(item){
      if(item.type===1){
        window.open("/model3dweb/#/?boxid=" + item.id);
      }else{
        this.$router.push({
          name: "modelitems",
          params: {
            id: item.id,
          },
        });
      }
    }
  },
};
</script>

<style lang="less" scoped>
.search-border {
  margin: 0;
  position: relative;
  padding: 0;
  display: flex;
  justify-content: space-between;
  width: 700px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #e9ebf0;
  border-radius: 12px;
  box-shadow: 0px 4px 10px 0px rgba(205, 205, 205, 0.1);
  .all-button-Box {
    display: flex;
    padding: 9px 14px;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #ebeced;
    .all-button {
      font-size: 16px;
      font-weight: 400;
      color: #404040;
      line-height: 22px;
    }

    .button-tips {
      width: 15px;
      height: 20px;
      margin-left: 8px;
      .button-ico {
        width: 100%;
        height: 100%;
      }
    }
    .icon-show {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      -webkit-transition: -webkit-transform 0.2s linear;
      transition: transform 0.2s linear;
    }
    .icon-unshow {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: -webkit-transform 0.2s linear;
      transition: transform 0.2s linear;
    }
  }
  .search-input-box {
    .search-input {
      width: 550px;
      height: 100%;
      padding: 18px 16px;
      border: none;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
    }
  }

  .search-btn {
    width: 40px;
    height: 40px;
    position: relative;
    top: -1px;
    right: -2px;
    border-radius: 12px;
    background-color: #ff6000;
    .search-btn-bg1 {
      width: 100%;
      height: 100%;
      display: block;
    }
    .search-btn-bg2 {
      width: 100%;
      height: 100%;
      display: none;
    }
  }
  .search-btn:hover {
    .search-btn-bg1 {
      width: 100%;
      height: 100%;
      display: none;
    }
    .search-btn-bg2 {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}
input:focus {
  outline: 0;
  border: none;
}
input {
  caret-color: #ff6000;
}
.pullist {
  position: absolute;
  z-index: 3;
  width: 86px;
  height: 140px;
  top: 66px;
  background-color: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  margin-left: -1px;
  transition: height 0.2s linear 0s;
  -webkit-transition: height 0.2s linear 0s;
  .pullist-item {
    margin-top: 5px;
    width: 100%;
    height: 30px;
    text-align: center;
    font-size: 16px;

    font-weight: 400;
    color: #404040;
    line-height: 30px;
  }
  .pullist-item:hover {
    background: #f1f2f4;
    color: #ff6000;
  }
}
.pullist-hidden {
  height: 0px;
  width: 86px;
  color: rgba(0, 0, 0, 0);
  position: absolute;
  overflow: hidden;
  transition: height 0.2s linear 0s;
  -webkit-transition: height 0.2s linear 0s;
}

.search-border:focus-within {
  border: 1px solid #ff6000;
}
//@media screen  and (min-width: 1000px) and (max-width: 1680px) {
//  #makewidth{
//    width: 400px !important;
//  }
//  .search-input-box{
//    .search-input {
//      width: 300px;
//    }
//  }
//
//}
</style>
