<template>
  <div class="Layout">
    <!-- 头部导航栏部分 -->
    <div class="top">
      <!-- active自定义属性，views页面传值 -->
      <NavItem :active="active" :list="navlist" :showsearch="showsearch" />
    </div>
    <!-- 中心 -->
    <div class="center">
      <!-- 左边导航栏 -->
      <div class="left">
        <div v-if="routemasgbol">
          <HelpMenuList />
        </div>
        <div v-else class="menulist-scorll">
          <MenuList />
        </div>
      </div>
      <!-- 右边内容 -->
      <div class="right" ref="scrollview" id="right-content-id" style="
          width: 100%;
          padding-left: 50px;
          padding-right: 50px;
          overflow: overlay !important;
        ">
        <div class="right1">
          <div class="right-content">
            <div class="fixed">
              <div v-if="routemasg === '/main'" class="backtop-box" @click="backtop">
              <img class="top-icon-hover" src="../../assets/layout/backtop.svg" />
              <img class="top-icon" src="../../assets/layout/backtop1.svg" />
            </div>
            <div v-if="routemasg === '/main'" class="kefu-box" @click="jump">
              <Poptip content="content" placement="left" width="200px">
                <p slot="content" class="customer">
                  <span>联系电话</span>
                  <span>15801358480</span>
                  <img src="@/assets/nodata.png" alt="" style="height: 120px; weight:120px">
                  <span>扫码添加客服咨询</span>
                </p>
                <Tooltip placement="left" theme="light">
                  <img class="kefu-icon-hover" src="../../assets/layout/kefuicon.svg" />
                  <img class="kefu-icon" src="../../assets/layout/kefuicon1.svg" />
                  <div slot="content">
                    <p>人工客服</p>
                    <p>工作日09：30-12：00，12：00-18：00</p>
                  </div>
                </Tooltip>
              </Poptip>
            </div>
            </div>
            <slot name="content"> </slot>
            <div class="footer-last">
<!--              <div class="bigbox">-->
<!--                <div class="footer-title">-->
<!--                  汇农创意系列产品-->
<!--                </div>-->
<!--                <div class="footer-word">个人版</div>-->
<!--                <div class="footer-word">企业版</div>-->
<!--              </div>-->
<!--              <div class="bigbox">-->
<!--                <div class="footer-title">-->
<!--                  服务-->
<!--                </div>-->
<!--                <div class="footer-word">帮助中心</div>-->
<!--                <div class="footer-word">公益及教育机构优惠</div>-->
<!--              </div>-->
<!--              <div class="bigbox">-->
<!--                <div class="footer-title">-->
<!--                  关于我们-->
<!--                </div>-->
<!--                <div class="footer-word">关于我们</div>-->
<!--                <div class="footer-word">工作机会</div>-->
<!--              </div>-->
<!--              <div class="bigbox">-->
<!--                <div class="footer-title">-->
<!--                  合作-->
<!--                </div>-->
<!--                <div class="footer-word">设计工作箱</div>-->
<!--                <div class="footer-word">开放平台</div>-->
<!--              </div>-->
<!--              <div class="bigbox">-->
<!--&lt;!&ndash;                <div class="footer-title">&ndash;&gt;-->
<!--&lt;!&ndash;                  联系我们&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="contact">&ndash;&gt;-->
<!--&lt;!&ndash;                  <img src="" alt="">&ndash;&gt;-->
<!--&lt;!&ndash;                  <img src="" alt="">&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="footer-word">咨询热线：400 2123 1231</div>&ndash;&gt;-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NavItem from "@/components/NavItem";
import MenuList from "@/components/MenuList";
import HelpMenuList from "@/components/HelpMenuList";
export default {
  name: "Layout",
  components: { NavItem, MenuList, HelpMenuList },
  data () {
    return {
      theme: "light",
      search: "",
      select: "all",
      navlist: [
        { name: "design", label: "设计服务" },
        { name: "help", label: "帮助中心" },
      ],
      showsearch: false,
      routemasg: "",
    };
  },
  props: {
    active: {
      type: String,
      default: "main",
    },
  },
  computed: {
    routemasgbol() {
      let bol = false
      let arr = [
        "/help",
        "/agreements",
        "/orderspayments",
        "/accountmanagement",
        "/functionintroduction",
        "/getstartedquickly",
        "/packagingknowledge",
        "/filerelated",
        "/issue"
      ]
      arr.forEach( url => {
        if(this.routemasg.indexOf(url)!=-1) {
          bol = true;
        }
      })
      return bol
    }
  },
  mounted () {
    // 获取指定元素
    let scrollview = this.$refs["scrollview"];
    // 添加滚动监听，该滚动监听了拖拽滚动条
    // 尾部的 true 最好加上，我这边测试没加 true ，拖拽滚动条无法监听到滚动，加上则可以监听到拖拽滚动条滚动回调
    scrollview.addEventListener("scroll", this.scrollChange, true);
    this.routemasg = this.$route.path;
  },
  methods: {
    // 滚动监听
    scrollChange () {
      let scrollTop = this.$refs["scrollview"].scrollTop;
      if (scrollTop >= 100) {
        this.showsearch = true;
      } else {
        this.showsearch = false;
      }
    },
    backtop () {
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        that.scrollTop = that.scrollTop + ispeed;
        if (that.scrollTop === 0 || that.scrollTop < 0) {
          clearInterval(timer);
        }
      }, 16);
    },
    jump () {
      // this.$router.push({ path: "/help" });
    },
  },
};
</script>
<style lang="less" scoped>
.Layout {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .top {
    width: 100%;
  }
  .center {
    display: flex;
    position: relative;
    width: 100%;
    height: calc(100% - 60px);
    .left {
      width: 230px;
      height: 100%;
      background-color: #fff;
      overflow-y: auto;
      overflow-x: hidden;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE10+ */
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
      .menulist-scorll {
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE10+ */
        *::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }
      }
    }
    .right {
      width: 100%;
      flex: 1;
      height: 100%;
      position: relative;
      overflow: hidden;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE10+ */
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
      .right1 {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 0px 40px;
        .right-content {
          margin: 0 auto;
          padding: 0px;
          scrollbar-width: none; /* Firefox */
          -ms-overflow-style: none; /* IE10+ */
          &::-webkit-scrollbar {
            display: none; /* Chrome Safari */
          }
        }
      }
    }
  }
}
.fixed {
  position: fixed;
  right: 10px;
  bottom: 30px;
  z-index: 2;
}
.backtop-box {
  // position: fixed;
  // z-index: 2;
  // top: 810px;
  // right: 20px;
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 20px;
  text-align: center;
  margin-bottom: 15px;
  .top-icon {
    width: 60%;
    padding-top: 20%;
    margin-left: 20%;
    display: block;
  }
  .top-icon-hover {
    width: 60%;
    padding-top: 20%;
    margin-left: 20%;
    display: none;
  }
}
.backtop-box:hover {
  .top-icon {
    width: 60%;
    padding-top: 20%;
    margin-left: 20%;
    display: none;
  }
  .top-icon-hover {
    width: 60%;
    padding-top: 20%;
    margin-left: 20%;
    display: block;
  }
}
.kefu-box {
  // position: fixed;
  // z-index: 2;
  // top: 860px;
  // right: 20px;
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 20px;
  text-align: center;
  .customer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .kefu-icon {
    width: 60%;
    margin-top: 20%;
    margin-left: 20%;
    display: block;
  }
  .kefu-icon-hover {
    width: 60%;
    margin-top: 20%;
    margin-left: 20%;
    display: none;
  }
}
.kefu-box:hover {
  .kefu-icon {
    width: 60%;
    margin-top: 20%;
    display: none;
  }
  .kefu-icon-hover {
    width: 60%;
    margin-top: 20%;
    display: block;
  }
}
.footer-last {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding-top: 80px;
  // border-top: 1px solid #d8dee5;
  .bigbox {
    .footer-title {
      font-size: 18px;
      font-weight: 500;
      color: #404040;
      padding-bottom: 12px;
    }
    .footer-word {
      font-size: 14px;
      font-weight: 400;
      color: #95979C;
      padding-bottom: 8px;
    }
    .contact {
      img {
        width: 67px;
        height: 67px;
        padding-right: 10px;
      }
    }
  }
}
</style>
