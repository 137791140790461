<template>
  <div>
    <button
      class="button-style"
      :style="{
        width: width + 'px',
        height: height + 'px',
        borderRadius: radius + 'px',
        color: color,
        background: bgcolor,
      }"
    >
      {{ text }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: 64,
    },
    height: {
      type: String,
      default: 32,
    },
    radius: {
      type: String,
      default: "0",
    },
    text: {
      type: String,
      default: "按钮",
    },
    bgcolor: {
      type: String,
      default: "#ff6000",
    },
    color: {
      type: String,
      default: "#FFFFFF",
    },
  },
};
</script>

<style lang="less" scoped>
.button-style {
  color: #ffffff;
  background: #ff6000;
  border: none;
}
.button-style:active {
  background: #c24d04;
}
</style>
