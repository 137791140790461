import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import liangdian from './liangdian'
import 'overlayscrollbars/overlayscrollbars.css'
import {
  OverlayScrollbars
} from 'overlayscrollbars'
//按需引入导入样式
import 'iview/dist/styles/iview.css';

//引入布局组件
import plugin from '@/plugin/iview.js'
import {
  getLs,
  removeLs
} from "@/liangdian/lib/storage";


window.IP = process.env.VUE_APP_IP


//亮点插件
Vue.use(liangdian, {
  filter: [
    "prod-api/userLogin",
    "prod-api/sendMobileCode",
    "prod-api/support/banner",
    "prod-api/box/boxlist",
    "prod-api/template/list",
    "prod-api/template/classify",
    "prod-api/user/notice",
    "prod-api/user/help/list",
    "prod-api/user/help/info"
  ],
  request: {
    success: (config, options) => {
      const token = getLs('token')
      let needToken = true
      options.filter.forEach(url => {
        if (config.url.indexOf(url) != -1) {
          needToken = false
          return
        }
      })
      if (token && needToken) {
        if (config.headers.common) {
          config.headers.common['Authorization'] = token
        } else {
          config.headers['Authorization'] = token
        }
      }
    },
    error: () => {

    }
  },
  response: {
    success: () => {

    },
    error: (msg, code) => {
      if (code == 401) {
        //token丢失后 在这里处理
        removeLs("token")
        removeLs("userId")
      } else {
        Vue.prototype.$Message.error(msg ? msg : '接口异常!')
      }
    }
  }
})

Vue.config.productionTip = false

Vue.prototype.OverlayScrollbars = dom => {
  OverlayScrollbars(dom, {
    overflow: {
      x: 'hidden',
    },
    scrollbars: {
      autoHide: 'leave'
    }
  })
}



new Vue({
  router,
  store,
  plugin,
  render: h => h(App)
}).$mount('#app')