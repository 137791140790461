var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-list-box"},[_c('div',{staticStyle:{"height":"100%"}},[_c('div',{staticStyle:{"padding-top":"40px"}}),_c('div',{class:_vm.titlelist[0].active ? 'item-box item-box-active' : 'item-box',on:{"click":function($event){return _vm.clickHandle(_vm.titlelist[0].url)}}},[_c('div',{staticClass:"list-icon"},[_c('img',{staticStyle:{"margin-top":"-1px","width":"100%","height":"100%"},attrs:{"src":_vm.titlelist[0].active
              ? require('../../assets/menulist/house.svg')
              : require('../../assets/menulist/house1.svg')}})]),_c('div',{class:_vm.titlelist[0].active ? 'item-title1' : 'item-title'},[_vm._v(" 首页 ")])]),_vm._m(0),_c('div',{class:_vm.titlelist[2].active ? 'item-box item-box-active' : 'item-box',on:{"click":function($event){return _vm.clickHandle(_vm.titlelist[2].url)}}},[_c('div',{staticClass:"list-icon"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.titlelist[2].active
              ? require('../../assets/menulist/favorites.svg')
              : require('../../assets/menulist/favorites1.svg')}})]),_c('div',{class:_vm.titlelist[2].active ? 'item-title1' : 'item-title'},[_vm._v(" 收藏夹 ")])]),_vm._m(1),_c('div',{class:_vm.titlelist[3].active ? 'item-box item-box-active' : 'item-box',on:{"click":function($event){return _vm.clickHandle(_vm.titlelist[3].url)}}},[_c('div',{staticClass:"list-icon"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.titlelist[3].active
              ? require('../../assets/menulist/model.svg')
              : require('../../assets/menulist/model1.svg')}})]),_c('div',{class:_vm.titlelist[3].active ? 'item-title1' : 'item-title'},[_vm._v(" 模板中心 ")])]),_c('div',{class:_vm.titlelist[4].active ? 'item-box item-box-active' : 'item-box',on:{"click":function($event){return _vm.clickHandle(_vm.titlelist[4].url)}}},[_c('div',{staticClass:"list-icon"}),_c('div',{class:_vm.titlelist[4].active ? 'item-title1' : 'item-title'},[_vm._v(" 水果类模板 ")])]),_c('div',{class:_vm.titlelist[6].active ? 'item-box item-box-active' : 'item-box',on:{"click":function($event){return _vm.clickHandle(_vm.titlelist[6].url)}}},[_c('div',{staticClass:"list-icon"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.titlelist[6].active
              ? require('../../assets/menulist/box.svg')
              : require('../../assets/menulist/box1.svg')}})]),_c('div',{class:_vm.titlelist[6].active ? 'item-title1' : 'item-title'},[_vm._v(" 盒型库 ")])]),_vm._m(2),_c('div',{staticClass:"item-box pudownlist",on:{"click":function($event){_vm.expressManage = !_vm.expressManage}}},[_vm._m(3),_c('div',[_c('img',{class:_vm.expressManage ? 'img-reserve' : 'unimg-reserve',attrs:{"src":require("../../assets/menulist/up.svg")}})])]),_c('div',{class:_vm.expressManage ? 'show' : 'unshow'},[_c('div',{class:_vm.titlelist[9].active ? 'item-box item-box-active' : 'item-box',on:{"click":function($event){return _vm.clickHandle(_vm.titlelist[9].url)}}},[_c('div',{staticClass:"list-icon"}),_c('div',{class:_vm.titlelist[9].active ? 'item-title1' : 'item-title'},[_vm._v(" 订单管理 ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-title-box"},[_c('div',{staticClass:"list-title"},[_vm._v("工作台")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-title-box"},[_c('div',{staticClass:"list-title"},[_vm._v("在线设计")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-title-box"},[_c('div',{staticClass:"list-title"},[_vm._v("工具箱")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pudownlist-item"},[_c('div',{staticClass:"list-icon"},[_c('img',{attrs:{"src":require("../../assets/menulist/delivery.svg")}})]),_c('div',{staticClass:"item-title"},[_vm._v("快递管理")])])
}]

export { render, staticRenderFns }