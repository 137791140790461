//简单缓存封装
export const setSs = (name, json) => {
    sessionStorage.setItem(name, JSON.stringify(json));
}
export const setLs = (name, json) => {
    localStorage.setItem(name, JSON.stringify(json));
}
export const removeSs = (name) => {
    sessionStorage.removeItem(name);
}
export const removeLs = (name) => {
    localStorage.removeItem(name);
}
export const getSs = name => {
    if (sessionStorage.getItem(name)) {
        let json = sessionStorage.getItem(name);
        try {
            return JSON.parse(json);
        }catch (e) {
            return null;
        }
    } else {
        return null;
    }
}
export const getLs = name => {
    if (localStorage.getItem(name)) {
        let json = localStorage.getItem(name);
        try {
            return JSON.parse(json);
        }catch (e) {
            return null;
        }
    } else {
        return null;
    }
}
export const clearLs = {
    include(list) {
        //都会被删
        for(let key in localStorage) {
            if(list.indexOf(key)!==-1) {
                localStorage.removeItem(key)
            }
        }
    },
    exclude(list) {
        //都不会被删
        for(let key in localStorage) {
            if(list.indexOf(key)===-1) {
                localStorage.removeItem(key)
            }
        }
    }
}
export const clearSs = {
    include(list) {
        //都会被删
        for(let key in sessionStorage) {
            if(list.indexOf(key)!==-1) {
                sessionStorage.removeItem(key)
            }
        }
    },
    exclude(list) {
        //都不会被删
        for(let key in sessionStorage) {
            if(list.indexOf(key)===-1) {
                sessionStorage.removeItem(key)
            }
        }
    }
}