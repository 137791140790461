<template>
  <div class="nav">
    <!-- Jump(传参)跳转事件，通过路由跳转到对应（item.name）页面 -->
    <Menu mode="horizontal" theme="light" active-name="1" style="height: 100%">
      <div class="logo-box">
        <div class="nav-logo" @click="Jump('/main')">
          <img src="/pdflogo.svg" alt="">
        </div>
      </div>
      <div class="nav-content">
        <div class="left-content">
          <div class="search">
            <SearchCmpTop v-if="showsearch" />
          </div>
        </div>
        <div style="width: 50px" ></div>
        <div class="right-content">
          <div class="manager-list">
            <div class="manager-items">
<!--              <div class="manager-item">-->
<!--                &lt;!&ndash; <span :key="index" v-for="(item, index) in list" @click="Jump(item.name)" :class="active==item.name?'before strong':'before'">{{item.label}}</span> &ndash;&gt;-->
<!--                &lt;!&ndash; <span>设计服务</span> &ndash;&gt;-->
<!--                <div class="nav-text">设计服务</div>-->
<!--              </div>-->
              <div class="manager-item" @click="Jump('/help')">
                <div class="nav-text">帮助中心</div>
              </div>
              <div class="manager-item">
                <Poptip placement="bottom-end">
                  <img
                    class="manager-icon"
                    src="../../assets/Navitem/massage1.png"
                  />
                  <div class="masssage-pop" slot="content">
                    <div class="out-title">系统通知</div>
                    <div class="line" />
                    <div class="masage-list">
                      <div class="list-item-box">
                        <img
                          class="list-item-img"
                          src="../../assets/Navitem/tips.png"
                        />
                        <div class="list-item-text">
                          <div class="list-item-title">
                            {{ noticeTitle }}
                          </div>
                          <div class="list-item-detail">
                            {{ noticeContent }}
                            <span class="blue-text">立即查看</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="line" />
                    <div class="button-box">
                      <div class="readed-text">全部已读</div>
                      <div class="blue-text">查看全部</div>
                    </div> -->
                  </div>
                </Poptip>
              </div>
            </div>
            <div>
              <div class="manager-btn" @click="loginButton" v-if="!loginStatus">
                <Dbutton width="88" height="36" radius="8" text="注册登陆" />
              </div>
              <!-- 点击头像的弹出框 -->
              <Poptip placement="bottom-end">
                <img
                  style="width: 32px; height: 32px; border-radius: 50%"
                  icon="ios-person"
                  v-if="loginStatus"
                  :src="src"
                />
                <div class="api" slot="content">
                  <div class="avatar">
                    <img :src="src" alt="">
                    <div class="avatar-text">
                      <div class="username">{{ nickName }}</div>
                      <!-- <div class="userid" id="userid">{{ userId }}</div> -->
                    </div>
                  </div>
                  <Divider />
                  <div>
                    <div class="avatar-item" @click="Jump('/admin')">
                      <img src="../../assets/mainSaveImg/account.svg" />
                      <div class="item-text">账号设置</div>
                    </div>
                    <div class="avatar-item" id="orders">
                      <img src="../../assets/mainSaveImg/order.svg" />
                      <div class="item-text" @click="jump">订单管理</div>
                    </div>
                  </div>
                  <Divider />
                  <div class="avatar-item" id="exit">
                    <img src="../../assets/mainSaveImg/exit.svg" />
                    <div class="item-text" @click="exitHandle">退出登录</div>
                  </div>
                </div>
              </Poptip>
            </div>
          </div>
        </div>
      </div>
    </Menu>
    <!-- 弹出框 -->
    <Modal
      v-model="this.$store.state.loginPage"
      :footer-hide="true"
      @on-cancel="cancelHandle"
      :styles="{ top: '280px' }"
      :width="480"
    >
      <div class="popup">
        <div class="popup-title">登录账号</div>
        <Input style="margin-top: 20px;" v-model="mobile" placeholder="请输入正确的手机号"></Input>
        <div class="number-box">
          <Input v-model="code" placeholder="输入验证码"></Input>
          <div @click="loginHandel">
            <Dbutton
              class="bumber-button"
              width="120"
              height="40"
              radius="8"
              :text="timer >= 60 ? '获取验证码' : `${timer}s`"
            />
          </div>
        </div>
        <div
          class="login-button"
          style="margin-top: 24px"
          @click="loginHandle()"
        >
          <Dbutton
            class="bumber-button"
            width="400"
            height="40"
            radius="8"
            text="登录"
          />
        </div>
      </div>
      <div class="popup-terms">
        <div class="terms-text" @Dradio="use_Dradio" style="display: flex">
          <div style="margin-left: 10px;">
            <Checkbox v-model="singlelogin">&nbsp;我已阅读<span style="color: blue">《用户协议》</span></Checkbox>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import md5 from "js-md5";
import Dbutton from "@/components/Dbutton";
import SearchCmpTop from "@/components/SearchCmpTop";
import Dinput from "@/components/Dinput";
import Dradio from "@/components/Dradio";
export default {
  name: "NavItem",
  components: {
    Dbutton,
    SearchCmpTop,
    Dinput,
    Dradio,
  },
  data() {
    return {
      modal1: false,
      single: false,
      // 用于登录成功的标记
      loginStatus: false,
      Verification: true, //通过v-show控制显示获取还是倒计时
      timer: 60, //定义初始时间为60s
      loginclick: true,
      code: "",
      mobile: "",
      nickName:"",
      singlelogin:false,
      noticeTitle: "",
      noticeContent: "",
      src: require("@/assets/logo_32.png"),
    };
  },
  props: {
    active: {
      type: String,
      default: "",
    },
    list: {
      type: Array,
      //父子组件传对象默认值(数组)
      default: () => [],
      /*
      type: Object,
      父子组件传对象默认值(对象)
      default：() => ({})
      */
    },
    showsearch: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.$store.state.userId && this.$getLs("token")) {
      this.loginStatus = true;
      // this.userId = this.$store.state.userId;
    };
    if (!this.$getLs("token")) {
      // this.$router.push('/unlogin');
    };
    if(this.loginStatus) {
      this.getuserInfo();
    };
    this.getnotice();
  },
  methods: {
    Jump(path) {
      this.$router.push({ path }).catch((err) => {});
    },
    loginHandle() {
      if (!this.mobile) return this.$Message.error("手机号不能为空!");
      if (!this.code) return this.$Message.error("验证码不能为空!");
      if(!this.singlelogin) return this.$Message.error("请同意协议!");
      this.axios
        .post(IP + "/prod-api/userLogin", {
          mobile: this.mobile,
          code: this.code,
        })
        .then((res) => {
          if (res.token) {
            this.$setLs("token", res.token);
            this.$setLs("userId", res.userId);
            this.loginStatus = true;
            this.$store.state.loginPage = false
            this.$store.commit("userId", res.userId);
            this.$Message.success("登录成功!");
            this.$router.push('/main');
            this.userId = res.userId;
          } else {
            this.$Message.error("token null");
          }
        });
    },
    jump() {
      this.$router.push({ path: "/ordermanage" });
    },
    loginButton() {
      this.$store.state.loginPage = true
    },
    // 单选框的回调事件
    use_Dradio(agreementStatus) {
      // console.log(agreementStatus);
    },
    loginHandel() {
      if (this.Verification == false) {
        return;
      } else {
        this.handleClick();
      }
    },
    handleClick() {
      this.Verification = false; //点击button改变v-show的状态
      this.getCaptcha();
      let auth_timer = setInterval(() => {
        //定时器设置每秒递减
        this.timer--; //递减时间
        if (this.timer <= 0) {
          this.Verification = true; //60s时间结束还原v-show状态并清除定时器
          clearInterval(auth_timer);
          this.timer = 60;
        }
      }, 1000);
    },
    //获取短信验证码
    getCaptcha() {
      if (!this.mobile) return this.$Message.error("手机号不能为空!");
      const timestamp = new Date().getTime() + "";
      let op = {
        mobile: this.mobile,
        timestamp,
        sign: md5(this.mobile + timestamp + "i3b0iNKtojZJfGz3"),
        isSend: "0",
      };
      this.axios
        .post(IP + "/prod-api/sendMobileCode", op)
        .then((res) => {
          //这里可以优化定时器的样式
        })
        .catch((e) => {
          this.Verification = true;
          this.$Message.error("获取验证码错误!");
          console.error("获取验证码异常:", e);
        });
    },
    //获取用户信息
    getuserInfo(){
      this.axios
      .get(IP + "/prod-api/user/userInfo")
      .then((res) => {
        this.nickName = res.data.nickName;
        this.src = res.data.avatar;
      })
    },
    //获取消息
    getnotice() {
      this.axios
      .get(IP + "/prod-api/user/notice")
      .then((res) => {
        this.noticeTitle = res.data.noticeTitle;
        this.noticeContent = res.data.noticeContent;
      })
    },
    cancelHandle(){
      this.$store.state.loginPage = false
    },
    exitHandle() {
      this.loginStatus = false;
      this.userId = "";
      this.$store.commit("userId", 0);
      this.$removeLs("token");
      this.$removeLs("userId");
      this.$router.push('/unlogin');
    },
  },
};
</script>
<style lang="less" scoped>
.nav {
  background-color: #fefefe;
  width: 100vw;
  height: 64px;
  padding: 0;
  margin: 0;
}
.logo-box {
  width: 300px;
  .nav-logo {
    width: 210px;
    height: 50px;
    border-radius: 3px;
    margin-left: 10px;
    float: left;
    img {
      width: 100%;
      height: 100%;
      vertical-align:middle
    }
  }
  // 设置logo
  // /deep/ .nav-logo[data-v-7fc5184e] {
  //   position: static;
  // }
}

.nav-content {
  line-height: 30px;
  width: 100%;
  padding: 0px 50px 0px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span:hover,
  .Icon:hover {
    color: rgba(0, 0, 0, 1);
    cursor: pointer;
  }
  .before {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.7);
  }
  .strong {
    // font-weight: 600;
    color: rgba(0, 0, 0, 1);
  }
  .left-content {
    display: flex;
    width: 1000px;
    .search {
      margin-left: 220px;
      margin-top: -35px;
    }
  }
  .right-content {
    font-size: 16px;
    display: flex;
    justify-content: right;
    margin-top: -40px;
  }
  .manager-list {
    display: flex;
    position: relative;
    top: 5px;
    width: 100%;
    margin: auto;
    height: 60px;
    justify-content: right;
    align-items: center;

    .manager-items {
      display: flex;
      margin-top: -2px;
      .manager-item {
        font-size: 16px;
        margin-right: 40px;
        .manager-icon {
          width: 25px;
          position: relative;
          top: 2px;
        }
      }
    }
    .manager-btn {
      position: relative;
      top: 10px;
    }
  }
}
.popup {
  padding: 0px 40px;
  padding-top: 40px;
  .popup-title {
    text-align: center;
    height: 22px;
    font-size: 16px;
    font-weight: 500;
    color: #404040;
    line-height: 22px;
  }
  .popup-tel {
    margin-top: 24px;
  }
  .number-box {
    margin-top: 24px;
    display: flex;
    .bumber-button {
      margin-left: 16px;
    }
  }
  .login-button {
    margin-top: 24px;
  }
}
.popup-terms {
  width: 100%;
  height: 52px;
  background: #f0f4f8;
  margin-top: 70px;
  display: flex;
  align-items: center;
  border-radius: 0px 0px 5px 5px;
  .terms-text {
    margin-left: 40px;
  }
}
.avatar {
  display: flex;
  align-items: center;
  padding: 15px 15px 0px 0px;
  img {
    height: 32px;
    width: 32px;
    border-radius: 50%;
  }

  .avatar-text {
    margin-left: 16px;
    .username {
      height: 20px;
      font-size: 14px;
      font-weight: 500;
      color: #323232;
      line-height: 20px;
    }
    .userid {
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #909090;
      line-height: 20px;
    }
    #userid {
      margin-top: 4px;
    }
  }
}
.avatar-item {
  display: flex;
  margin-top: -10px;
  padding: 10px 0px;
  border-radius: 5px;
  .item-text {
    margin-left: 8px;
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #404040;
    line-height: 20px;
  }
}
.avatar-item:hover {
  background-color: #f1f2f4;
  transition: all 0.3s;
}
#orders {
  margin-top: 15px;
}
#exit {
  padding-bottom: 15px;
}

/deep/ .ivu-modal-body {
  padding: 0px;
}
.masssage-pop {
  .line {
    width: 100%;
    height: 1px;
    margin-bottom: 8px;
    background-color: #d8dee5;
  }
  .out-title {
    font-size: 14px;
    padding: 15px 0px;
    font-weight: 600;
    color: #323232;
    line-height: 20px;
  }
  .masage-list {
    width: 100%;
    height: 296px;
    .list-item-box {
      display: flex;
      padding: 12px 0px;
      align-items: center;
      .list-item-img {
        width: 32px;
        height: 32px;
      }
    }
  }

  .list-item-text {
    margin-left: 8px;
    .list-item-title {
      height: 20px;
      font-size: 14px;
      font-weight: 500;
      color: #323232;
      line-height: 20px;
    }
    .list-item-detail {
      margin-top: 4px;
      width: 294px;
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #909090;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis; //文本溢出显示省略号
      white-space: nowrap; //文本不会换行
    }
  }
  .button-box {
    display: flex;
    justify-content: space-between;
    padding: 14px 0px;
  }
  .blue-text {
    font-size: 14px;
    font-weight: 500;
    color: #1147f3;
    line-height: 20px;
  }
  .readed-text {
    font-size: 14px;
    font-weight: 400;
    color: #404040;
    line-height: 20px;
  }
}
.nav-text:hover {
  color: #ff6000;
  cursor: default;
}
/deep/ .ivu-input{
  height: 40px;
}
/deep/ .ivu-input:hover{
  border-color: #ff6000;
}
/deep/ .ivu-input:focus {
  border-color: #ff6000;
  outline: 0;
  box-shadow: 0 0 0 2px rgb(255 96 0 /20%);
}
</style>
