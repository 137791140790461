<template>
  <div class="menu-list-box">
    <div style="height: 100%">
      <div style="padding-top: 40px"></div>
      <div
        :class="titlelist[0].active ? 'item-box item-box-active' : 'item-box'"
        @click="clickHandle(titlelist[0].url)"
      >
        <div class="list-icon">
          <img
            style="margin-top: -1px; width: 100%; height: 100%"
            :src="
              titlelist[0].active
                ? require('../../assets/menulist/house.svg')
                : require('../../assets/menulist/house1.svg')
            "
          />
        </div>
        <div :class="titlelist[0].active ? 'item-title1' : 'item-title'">
          首页
        </div>
      </div>
      <div class="list-title-box">
        <div class="list-title">工作台</div>
      </div>
      <!-- <div
        :class="titlelist[1].active ? 'item-box item-box-active' : 'item-box'"
        @click="clickHandle(titlelist[1].url)"
      >
        <div class="list-icon">
          <img
            style="width: 100%; height: 100%"
            :src="
              titlelist[1].active
                ? require('../../assets/menulist/sheji.svg')
                : require('../../assets/menulist/sheji1.svg')
            "
          />
        </div>
        <div :class="titlelist[1].active ? 'item-title1' : 'item-title'">
          设计管理
        </div>
      </div> -->
      <div
        :class="titlelist[2].active ? 'item-box item-box-active' : 'item-box'"
        @click="clickHandle(titlelist[2].url)"
      >
        <div class="list-icon">
          <img
            style="width: 100%; height: 100%"
            :src="
              titlelist[2].active
                ? require('../../assets/menulist/favorites.svg')
                : require('../../assets/menulist/favorites1.svg')
            "
          />
        </div>
        <div :class="titlelist[2].active ? 'item-title1' : 'item-title'">
          收藏夹
        </div>
      </div>
      <div class="list-title-box">
        <div class="list-title">在线设计</div>
      </div>
      <div
        :class="titlelist[3].active ? 'item-box item-box-active' : 'item-box'"
        @click="clickHandle(titlelist[3].url)"
      >
        <div class="list-icon">
          <img
            style="width: 100%; height: 100%"
            :src="
              titlelist[3].active
                ? require('../../assets/menulist/model.svg')
                : require('../../assets/menulist/model1.svg')
            "
          />
        </div>
        <div :class="titlelist[3].active ? 'item-title1' : 'item-title'">
          模板中心
        </div>
      </div>
      <div
        :class="titlelist[4].active ? 'item-box item-box-active' : 'item-box'"
        @click="clickHandle(titlelist[4].url)"
      >
        <div class="list-icon"></div>
        <div :class="titlelist[4].active ? 'item-title1' : 'item-title'">
          水果类模板
        </div>
      </div>
      <!-- <div
        :class="titlelist[5].active ? 'item-box item-box-active' : 'item-box'"
        @click="clickHandle(titlelist[5].url)"
      >
        <div class="list-icon"></div>
        <div :class="titlelist[5].active ? 'item-title1' : 'item-title'">
          蔬菜类模板
        </div>
      </div> -->
      <div
        :class="titlelist[6].active ? 'item-box item-box-active' : 'item-box'"
        @click="clickHandle(titlelist[6].url)"
      >
        <div class="list-icon">
          <img
            style="width: 100%; height: 100%"
            :src="
              titlelist[6].active
                ? require('../../assets/menulist/box.svg')
                : require('../../assets/menulist/box1.svg')
            "
          />
        </div>
        <div :class="titlelist[6].active ? 'item-title1' : 'item-title'">
          盒型库
        </div>
      </div>
      <div class="list-title-box">
        <div class="list-title">工具箱</div>
      </div>
      <!-- <div class="item-box pudownlist" @click="mudelmanage = !mudelmanage">
        <div class="pudownlist-item">
          <div class="list-icon">
            <img src="../../assets/menulist/material.svg" />
          </div>
          <div class="item-title">素材管理</div>
        </div>
        <div :class="mudelmanage ? 'img-reserve' : 'unimg-reserve'">
          <img src="../../assets/menulist/up.svg" />
        </div>
      </div>
      <div :class="mudelmanage ? 'show' : 'unshow'">
        <div
          :class="titlelist[7].active ? 'item-box item-box-active' : 'item-box'"
          @click="clickHandle(titlelist[7].url)"
        >
          <div class="list-icon"></div>
          <div :class="titlelist[7].active ? 'item-title1' : 'item-title'">
            批量上传
          </div>
        </div>
        <div
          :class="titlelist[8].active ? 'item-box item-box-active' : 'item-box'"
          @click="clickHandle(titlelist[8].url)"
        >
          <div class="list-icon"></div>
          <div :class="titlelist[8].active ? 'item-title1' : 'item-title'">
            批量删除
          </div>
        </div>
      </div> -->
      <div class="item-box pudownlist" @click="expressManage = !expressManage">
        <div class="pudownlist-item">
          <div class="list-icon">
            <img src="../../assets/menulist/delivery.svg" />
          </div>
          <div class="item-title">快递管理</div>
        </div>
        <div>
          <img
            :class="expressManage ? 'img-reserve' : 'unimg-reserve'"
            src="../../assets/menulist/up.svg"
          />
        </div>
      </div>
      <div :class="expressManage ? 'show' : 'unshow'">
        <div
          :class="titlelist[9].active ? 'item-box item-box-active' : 'item-box'"
          @click="clickHandle(titlelist[9].url)"
        >
          <div class="list-icon"></div>
          <div :class="titlelist[9].active ? 'item-title1' : 'item-title'">
            订单管理
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 用于素材管理的显示和隐藏
      mudelmanage: true,
      // 用于快递管理的显示和隐藏
      expressManage: true,
      titlelist: [
        {
          active: false,
          title: "首页",
          url: "/main",
        },
        {
          active: false,
          title: "设计管理",
          url: "/design",
        },
        {
          active: false,
          title: "收藏夹",
          url: "/favorites",
        },
        {
          active: false,
          title: "模板中心",
          url: "/templatecenter",
        },
        {
          active: false,
          title: "水果类模板",
          url: "/templatefruit",
        },
        {
          active: false,
          title: "蔬菜类模板",
          url: "/templatevegetable",
        },
        {
          active: false,
          title: "盒型库",
          url: "/boxlibrary",
        },
        {
          active: false,
          title: "批量上传",
          url: "/favorites",
        },
        {
          active: false,
          title: "批量删除",
          url: "/favorites",
        },
        {
          active: false,
          title: "订单进度",
          url: "/ordermanage",
        },
      ],
    };
  },
  mounted() {
    for (let i = 0; i < this.titlelist.length; i++) {
      if (this.titlelist[i].url == this.$route.path) {
        this.titlelist[i].active = true;
      } else {
        this.titlelist[i].active = false;
      }
    }
  },
  methods: {
    clickHandle(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style lang="less" scoped>
.menu-list-box {
  width: 100%;
  height: 92vh;
  background-color: #ffffff;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE10+ */
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .item-box {
    display: flex;
    width: 178px;
    align-items: center;
    height: 40px;
    padding: 0px 16px;
    border-radius: 3px;
    margin: 0px auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE10+ */
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .list-icon {
      width: 16px;
      height: 16px;
    }
    .item-title {
      margin-left: 15px;
      // height: 22px;
      font-size: 14px;
      font-weight: 500;
      color: #323232;
    }
    .item-title1 {
      margin-left: 15px;
      // height: 22px;
      font-size: 14px;
      font-weight: 500;
      color: #ff6000;
    }
  }
  .item-box:hover {
    background-color: #f1f2f4;
    transition: all 0.3s;
  }
  .item-box-active {
    background-color: #f1f2f4;
  }
  .pudownlist {
    justify-content: space-between;
    .pudownlist-item {
      display: flex;
    }
  }
  .list-title-box {
    margin: 0 auto;
    padding: 24px 16px 30px 15px;
    display: flex;
    width: 220px;
    height: 36px;
    .list-title {
      height: 22px;
      font-size: 16px;

      font-weight: 500;
      color: #323232;
      line-height: 22px;
    }
  }
  .unshow {
    height: 0px;
    overflow: hidden;
    transition: all 0.2s linear 0s;
    -webkit-transition: all 0.1s linear 0s;
  }
  .show {
    height: 100px;
    overflow: hidden;
    transition: all 0.2s linear 0s;
    -webkit-transition: all 0.2s linear 0s;
  }
  .img-reserve {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: -webkit-transform 0.1s linear;
    transition: transform 0.1s linear;
  }
  .unimg-reserve {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: -webkit-transform 0.1s linear;
    transition: transform 0.1s linear;
  }
}
</style>
