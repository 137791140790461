<template>
  <div class="menu-list-box">
    <div style="height: 100%">
      <div style="padding-top: 40px"></div>
      <div
        :class="titlelist[0].active ? 'item-box item-box-active' : 'item-box'"
        @click="clickHandle(titlelist[0].url)"
      >
        <div class="list-icon">
          <img
            style="width: 100%; height: 100%"
            :src="
              titlelist[0].active
                ? require('../../assets/menulist/help.svg')
                : require('../../assets/menulist/help1.svg')
            "
          />
        </div>
        <div :class="titlelist[0].active ? 'item-title1' : 'item-title'">
          帮助中心
        </div>
      </div>
      <div
        :class="titlelist[1].active ? 'item-box item-box-active' : 'item-box'"
        @click="clickHandle(titlelist[1].url)"
      >
        <div class="list-icon">
          <img
            style="width: 100%; height: 100%"
            :src="
              titlelist[1].active
                ? require('../../assets/menulist/model.svg')
                : require('../../assets/menulist/model1.svg')
            "
          />
        </div>
        <div :class="titlelist[1].active ? 'item-title1' : 'item-title'">
          模板中心
        </div>
      </div>
      <div
        :class="titlelist[2].active ? 'item-box item-box-active' : 'item-box'"
        @click="clickHandle(titlelist[2].url)"
      >
        <div class="list-icon">
          <img
            style="width: 100%; height: 100%"
            :src="
              titlelist[2].active
                ? require('../../assets/menulist/box.svg')
                : require('../../assets/menulist/box1.svg')
            "
          />
        </div>
        <div :class="titlelist[2].active ? 'item-title1' : 'item-title'">
          盒型库
        </div>
      </div>
      <div class="item-box pudownlist" style="margin-top: 450px" @click="clickHandle(titlelist[3].url)">
        <div class="pudownlist-item">
          <div
            :class="titlelist[3].active ? 'item-title1' : 'item-title'"
            style="margin-left: 0px"
          >
            快速上手
          </div>
        </div>
        <div>
          <img class="unimg-reserve" src="../../assets/menulist/more.svg" />
        </div>
      </div>

      <div class="item-box pudownlist">
        <div class="pudownlist-item">
          <div
            :class="titlelist[4].active ? 'item-title1' : 'item-title'"
            style="margin-left: 0px"
          >
            账号相关
          </div>
        </div>
        <div>
          <img class="unimg-reserve" src="../../assets/menulist/more.svg" />
        </div>
      </div>

      <div class="item-box pudownlist" @click="expressManage = !expressManage">
        <div class="pudownlist-item">
          <div
            :class="titlelist[5].active ? 'item-title1' : 'item-title'"
            style="margin-left: 0px"
          >
            包装相关
          </div>
        </div>
        <div>
          <img  :class="expressManage ? 'img-reserve' : 'unimg-reserve'" src="../../assets/menulist/more.svg" />
        </div>
      </div>
      <div :class="expressManage ? 'show' : 'unshow'">
        <div
            :class="titlelist[7].active ? 'item-box item-box-active' : 'item-box'"
            @click="clickHandle(titlelist[7].url)"
        >
          <div class="list-icon"></div>
          <div style="margin-left: 0px" :class="titlelist[7].active ? 'item-title1' : 'item-title'">
            包装知识
          </div>
        </div>
        <div
            :class="titlelist[8].active ? 'item-box item-box-active' : 'item-box'"
            @click="clickHandle(titlelist[8].url)"
        >
          <div class="list-icon"></div>
          <div style="margin-left: 0px" :class="titlelist[8].active ? 'item-title1' : 'item-title'">
            文件相关
          </div>
        </div>
      </div>


      <div class="item-box pudownlist" @click="clickHandle(titlelist[6].url)">
        <div class="pudownlist-item">
          <div
            :class="titlelist[6].active ? 'item-title1' : 'item-title'"
            style="margin-left: 0px"
          >
            协议条款
          </div>
        </div>
      </div>

      <div
        class="item-box"
        style="height: 76px; background-color: #f1f2f4; margin-top: 4px"
      >
        <div>
          <img src="../../assets/menulist/kefu.svg" />
        </div>
        <div class="kefu-box">
          <div class="kefu-title">问题仍未解决？</div>
          <div class="kefu-detail">在线咨询客服>></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 用于素材管理的显示和隐藏
      mudelmanage: true,
      // 用于快递管理的显示和隐藏
      expressManage: false,
      titlelist: [
        {
          active: true,
          title: "帮助中心",
          url: "/help",
        },
        {
          active: false,
          title: "模板中心",
          url: "/templatecenter",
        },
        {
          active: false,
          title: "盒型库",
          url: "/boxlibrary",
        },
        {
          active: false,
          title: "快速上手",
          url: "/getstartedquickly",
        },
        {
          active: false,
          title: "账号相关",
          url: "/help2",
        },
        {
          active: false,
          title: "包装相关",
          url: "/help3",
        },
        {
          active: false,
          title: "协议条款",
          url: "/agreements",
        },
        {
          active: false,
          title: "包装知识",
          url: "/packagingknowledge",
        },
        {
          active: false,
          title: "文件相关",
          url: "/filerelated",
        },
      ],
    };
  },
  mounted() {
    for (let i = 0; i < this.titlelist.length; i++) {
      if (this.titlelist[i].url == this.$route.path) {
        this.titlelist[i].active = true;
      } else {
        this.titlelist[i].active = false;
      }
    }
  },
  methods: {
    clickHandle(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style lang="less" scoped>
.menu-list-box {
  width: 100%;
  height: 92vh;
  background-color: #ffffff;
  .item-box {
    display: flex;
    width: 178px;
    align-items: center;
    height: 40px;
    padding: 0px 16px;
    border-radius: 3px;
    margin: 4px auto;
    .list-icon {
      width: 16px;
      height: 16px;
    }
    .item-title {
      margin-left: 15px;
      // height: 22px;
      font-size: 14px;
      font-weight: 500;
      color: #323232;
    }
    .item-title1 {
      margin-left: 15px;
      // height: 22px;
      font-size: 14px;
      font-weight: 500;
      color: #ff6000;
    }
  }
  .item-box:hover {
    background-color: #f1f2f4;
    transition: all 0.3s;
  }
  .item-box-active {
    background-color: #f1f2f4;
  }
  .pudownlist {
    justify-content: space-between;
    .pudownlist-item {
      display: flex;
    }
  }
  .list-title-box {
    margin: 0 auto;
    padding: 24px 16px 30px 15px;
    display: flex;
    width: 220px;
    height: 36px;
    .list-title {
      height: 22px;
      font-size: 16px;

      font-weight: 500;
      color: #323232;
      line-height: 22px;
    }
  }
  .unshow {
    height: 0px;
    overflow: hidden;
    transition: all 0.2s linear 0s;
    -webkit-transition: all 0.1s linear 0s;
  }
  .show {
    height: 100px;
    overflow: hidden;
    transition: all 0.2s linear 0s;
    -webkit-transition: all 0.2s linear 0s;
  }
  .img-reserve {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transition: -webkit-transform 0.1s linear;
    transition: transform 0.1s linear;
  }
  .unimg-reserve {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: -webkit-transform 0.1s linear;
    transition: transform 0.1s linear;
  }
}
.kefu-box {
  .kefu-title {
    width: 98px;
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #404040;
    line-height: 20px;
  }
  .kefu-detail {
    width: 106px;
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #1147f3;
    line-height: 20px;
  }
}
</style>
