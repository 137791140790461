<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
   created() { 
    if( this.$getLs("userId")) {
      this.$store.commit("userId", this.$getLs("userId"));
    }
       
   }
}
</script>
<style lang="less" scoped>
#app {
  height: 100%;
  background: #f6f7f9;
}
</style>
<style>
*::-webkit-scrollbar {
  width: 5px; /* 纵向滚动条*/
  height: 5px; /* 横向滚动条 */
  background-color: #fff;
}

/*定义滚动条轨道 内阴影*/
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #ccc;
}

/*定义滑块 内阴影*/
*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #5b6269;
  border-radius: 10px;
}
</style>
