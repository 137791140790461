<template>
  <div>
    <span class="Dradio" @click="Dradio">
      <img src="@/assets/radioCmp/notClicked.png" v-show="!agreementStatus" />
      <img src="@/assets/radioCmp/Clicked.png" v-show="agreementStatus" />
    </span>

  </div>
</template>
<script>
export default {
  data () {
    return {
      agreementStatus: false
    }
  },
  methods: {
    Dradio () {
      this.agreementStatus = !this.agreementStatus
      this.$emit("Dradio", this.agreementStatus)
    }
  },
}
</script>
<style lang='less' scoped>
.Dradio {
  img {
    width: 13px;
    height: 13px;
    background: #ffffff;
  }
}
</style>