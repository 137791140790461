//普通树
const toTree = _arr=> {
    let list = []
    _arr.forEach((item,index)=> {
        item.title = item.menuname
        // item.expand = true
        let n = _arr.findIndex(o=>o.menuid == item.parentid)
        if(n!==-1) {
           if(!_arr[n].children)_arr[n].children =[];
            _arr[n].children.push(item)
            list.push(index)
        }
    })
    return _arr.filter((item, index)=>list.indexOf(index)===-1)
}
//
const toTreeCourse = _arr=> {
    let list = []
    _arr.forEach((item,index)=> {
        item.title = item.name
        // item.expand = true
        let n = _arr.findIndex(o=>o.courseid == item.parentid)
        if(n!==-1) {
            if(!_arr[n].children)_arr[n].children =[];
            _arr[n].children.push(item)
            list.push(index)
        }
    })
    return _arr.filter((item, index)=>list.indexOf(index)===-1)
}
//对比树
const toTreeToTree = (_arr, _list)=> {
    let list = []
    _arr.forEach((item,index)=> {
        item.title = item.menuname
        // item.expand = true
        let n = _arr.findIndex(o=>o.menuid == item.parentid)
        if(n!==-1) {
            if(!_arr[n].children)_arr[n].children =[];
            _arr[n].children.push(item)
            list.push(index)
        }
    })
    return _arr.filter((item, index)=>{
        if(_list.findIndex(o=>o.menuid == item.menuid)!==-1 && !item.children) {
            item.checked = true
        }
        return list.indexOf(index)===-1
    })
}
export { toTree, toTreeToTree, toTreeCourse }


